import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Divider, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
//import NumericInput from 'react-numeric-input';
// import productImage from '../../../images/product.png';
import productImage from '../../../../images/product.png';
//import Close from "@material-ui/icons/HighlightOff";
//import moment from "moment";

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import {connect} from 'react-redux';
// import {cartActions, searchActions} from "../../../../_actions";
import {cartActions} from '../../../../_actions';

//import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PriceToStr from '../../../../_components/PriceToStr';
// import CountDay from "../../../../_components/CountDay";
// import BonusLabel from "../../../../_components/BonusLabel";
//import MyNumericInput from "../../../../_components/MyNumericInput";
import BasketAdd from '../../../../icons/BasketAdd';
import Cansel from '../../../../icons/Cancel';
import MyNumericInput from '../../../../_components/MyNumericInput';
import {Link} from 'react-router-dom';
//import ShoppingCartIcon from "../../../../_components/ShoppingCartIcon/ShoppingCartIcon";

import i18n from '../../../../i18n';
import {useTranslation} from 'react-i18next';

import product from '../../../../images/product.png';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import PromoLabel from '../../../../_components/PromoLabel';
import ImageWithFallback from '../../../../_components/ImageWithFallback';
import IconDiscount from '../../../../images/icon_discount.svg';
import {AddToCartSelector} from '../../../../_components/Selector/AddToCartSelector';
import addToCartPromo from '../../../../images/add-to-cart-promo.png';

const useStyles = makeStyles(theme => ({
  divider: {
    //margin: theme.spacing(1, 0),
    margin: '4px 0',
  },
  stMain: {
    // width: 340,
    // maxWidth: 340
  },
  stMainHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#414D5F',
  },
  stMainHeaderLink: {
    fontSize: 16,
    display: 'inline-block',
    fontWeight: 'bold',
    margin: '0 0 10px 0',
    color: '#414D5F',
    '&:hover': {
      color: '#315EFB',
      textDecoration: 'underline',
    },
  },
  stBrand: {
    fontSize: 14,
    //fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis' /* Многоточие */,
  },
  stName: {
    marginTop: 3,
    fontSize: 14,
    fontWeight: 400,

    //whiteSpace: 'nowrap',
    //overflow: 'hidden',
    //textOverflow: 'ellipsis', /* Многоточие */
  },
  stPriceHeader: {
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 700,
    marginTop: 5,
  },
  stOffers: {
    width: '100%',
    maxHeight: 200,
    overflowY: 'auto',
    //overflowY: "hidden",
    '&::-webkit-scrollbar': {width: 8, backgroundColor: '#fff'},
    '&::-webkit-scrollbar-thumb': {backgroundColor: '#b0bec5'},
  },
  stBalance: {
    marginBottom: 10,
    // marginTop: 6,
    fontFamily: 'sans-serif',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },

  disabledRow: {
    // background: 'green',
    // pointerEvents: 'none'
    '& input': {
      pointerEvents: 'none',
    },
  },
  disabled: {
    opacity: '0.6',
    pointerEvents: 'none',
  },

  stSelect: {
    width: '100%',
    height: 40,
    marginBottom: 16,
  },
  stPrice: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  gridContainerHr: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    /*border: '1px solid #eeeeee',
        boxShadow: '0 1px 2px #eeeeee',
        borderRadius: 5,*/
    padding: 0,
    width: '100%',
    marginBottom: 8,
    //margin: 5,
  },

  gridContainerBl: {
    padding: '0 2px',
    alignItems: 'center',
    display: 'inline-grid',
    //justifyContent: 'space-between',
    //border: '1px solid red',

    //height: 80,
    //maxHeight: 120,
  },

  gridContainerSubBlCont: {
    padding: 0,
    alignItems: 'flex-start',
    display: 'inline-block',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },

  signOutButton: {
    // color: 'white',
    // backgroundColor: 'darkorange',
    minWidth: 40,
    padding: '4px 8px',
  },

  primaryPrice: {
    lineHeight: 1.2,
    //textAlign: 'right',
    fontSize: 14,
    fontWeight: 700,
  },
  secondaryPrice: {
    lineHeight: 1.2,
    //textAlign: 'right',
    fontSize: 12,
  },

  primaryPriceBig: {
    //lineHeight: 1.2,
    //textAlign: 'right',
    fontSize: 16,
    fontWeight: 700,
  },
  secondaryPriceBig: {
    //lineHeight: 1.2,
    //textAlign: 'right',
    fontSize: 14,
  },

  disabledWarehouse: {
    opacity: 0.5,
    // background:'red',

    // pointerEvents: 'none'
    '& button': {
      backgroundColor: '#a8a8a8 !important',
      pointerEvents: 'none',
    },
    '& input': {
      // backgroundColor: '#dddd !important',
      pointerEvents: 'none',
    },

    '& .warehouseHelpBtn': {
      opacity: 1,
    },
  },

  mainImg: {maxWidth: '99%', maxHeight: '80px'},

  stMainHeaderHelp: {
    color: '#008CB4',
    fontSize: 18,
    fontWeight: 'bold',
  },

  warehouseHelpContent: {
    color: '#606060',
    fontSize: 14,
    paddingBottom: 20,
    '& p': {
      margin: '6px 0',
    },
  },

  warehouseHelpBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    color: '#315EFB',
    border: '2px solid #315EFB',
    borderRadius: '50%',
    fontSize: 14,
    fontWeight: 700,
    cursor: 'pointer',
    opacity: 1,
  },

  warehouseHelp: {
    width: '100px',
    height: '30px',
    // border: '1px solid #000',
    // borderRadius: '50%',
    fontSize: 12,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'absolute',
    right: -10,
    top: '50%',
    transform: 'translate(0, -50% )',
    // background: 'green',
    zIndex: 999,
  },
  warehouseNameHolder: {
    display: 'flex',
    alignItems: 'center',

    '& div': {
      marginRight: '10px',
    },
  },

  discounts_prev: {
    width: '100%',
    padding: '10px',
    background: '#414D5F',
    margin: '10px 0 0 0',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    borderRadius: 4,
    justifyContent: 'space-between',
  },
}));

function myFormat(num) {
  return num > 0
    ? i18n.t('common:for') + num + i18n.t('common:pieces')
    : i18n.t('common:item_not_selected');
  //return num;
}

let localMainCartId = 0;
let offers = [];
let discountQuantityArr = [];
let uuidArr = [];
let findQuantity = [];
let maxQuantityInWarehouse = 0;
//let priceContr = [];
let idDetail = '';

function getTotal(offers) {
  let total2 = 0;
  let count = 0;

  for (let i in offers) {
    //total2 += offers[i].quantity * offers[i].price;
    total2 += offers[i].quantity * offers[i].priceCurrent;
    count += offers[i].quantity;
  }
  return [total2, count];
  /*offers.forEach((value, index) => {
    });*/
}

const AddToCartDialog = props => {
  const classes = useStyles();
  const {
    //currencyMainContr,
    //currencyMainSite,
    //customer_data,
    // currencyMain,
    cart,
    mainCartId,
    dialogData,
    handleClose,
    // priorityParams,
    customerGroupId,
    authentication,
    getCart,
    getCountGoodsForUuid,
    // dataPromotionList,
    // searchPromotionList,
    discountsByProduct,
    sell_currency_id,
    currencies,
    imageDiscount,
    isDiscount,
    lastItemBreadcrumb,
  } = props;
  const currencyMain =
    sell_currency_id &&
    currencies &&
    currencies.find(x => x.id === sell_currency_id);
  
  const [flag, setFlag] = useState(0);
  const [unBlockWarehouse, setUnBlockWarehouse] = useState(1);
  const [warehouseHelpText, setWarehouseHelpText] = useState(false);
  const [promoModal, setPromoModal] = useState(false);
  const [warehouse, setWarehouse] = useState([]);
  const [quantityPriorityWarehouse, setQuantityPriorityWarehouse] = useState(
    [],
  );
  const [blockButton, setBlockButton] = useState(0);
  const [sortArrPriority, setSortArrPriority] = useState([]);
  const [quantityInWarehouse, setQuantityInWarehouse] = useState();
  // const [uuidArr, setUuidArr] = useState([]);
  // const [sortPriceDiscount, setSortPriceDiscount] = useState([]);
  const {row, indexRows, type} = dialogData;

  let findDiscountStep = [];
  let lastDiscountsByProductElem = [];
  let abbr = currencyMain ? currencyMain.abbr : 'UAH';
  // let discounts_by_quantity_sum_of_order = row && row.balance[0].discounts_by_quantity_sum_of_order.discounts_by_product[0].discounts

  useEffect(() => {
    // eslint-disable-next-line

    if (dialogData.row) {
      if (row.promoLabel) {
        // searchPromotionList(row.promoLabel[0].promotion_url, row.promoLabel[0].promotion_id);
        // setPromoModalData(dataPromotionList);
      }
    } else {
      offers = [];
      setUnBlockWarehouse(1);
    }
    addPriorityParams();
    let rowBalance = [];

    dialogData.row &&
      dialogData.row.balance.map((el, index) => {
        return rowBalance.push(el.quantity_int);
      });

    setQuantityInWarehouse(Math.max.apply(null, rowBalance));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogData.row]);

  useEffect(() => {
    // if(row &&  row.discounts_by_quantity_sum_of_order.discounts_by_product[0].promotion_type === "FROM_THE_QUANTITY_OF_GOODS") {
    //     discounts_by_quantity_sum_of_order = row.discounts_by_quantity_sum_of_order.discounts_by_product[0].discounts;
    //     discounts_by_quantity_sum_of_order.map((item, index) => {
    //         findDiscountStep.push(item.quantity)
    //     });
    // }

    filterDiscountOffer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers]);
  const [isAllDiscountsExsist, setIsAllDiscountsExsist] = useState(null);
  const [addedQuantity, setAddedQuantity] = useState(0);
  useEffect(() => {
    setIsAllDiscountsExsist(row?.balance.find(i => i.quantity_int > 1));
  }, [row]);

  function warehouseHelp(uuid) {
    setWarehouseHelpText(uuid);
  }

  const {t} = useTranslation();
  if (!dialogData.row) return <React.Fragment />;
  let abr =
    type +
    '#' +
    row.brand_clean +
    '#' +
    row.article_clean +
    '#' +
    row.brand_orig_clean +
    '#' +
    row.article_orig_clean +
    '#' +
    indexRows;
  const createMarkup = html => ({__html: html});

  let countBeforeDiscount =
    filterDiscountOffer()[0][0]?.quantity - addedQuantity;

  if (idDetail !== abr) {
    idDetail = abr;
    offers = [];
    if (row.balance) {
      row.balance.map(val => {
        // debugger

        if (val.countInCart)
          offers[val.uuid] = {
            quantity: val.countInCart,
            price: row.price,
            priceCurrent:
              row.priceCurrent !== undefined
                ? row.priceCurrent
                : row.price_all_curr[abbr].price_discount,
            uuid: val.uuid,
          };
        discountQuantityArr.push(val.countInCart);
        uuidArr.push(val.uuid);
        //priceContr[val.uuid] = tmpPrice;
        return true;
      });
    } else {
      row.offers.map(val => {
        if (val.countInCart)
          offers[val.uuid] = {
            quantity: val.countInCart,
            price: val.price,
            priceCurrent:
              row.priceCurrent !== undefined
                ? row.priceCurrent
                : row.price_all_curr[abbr].price_discount,
            uuid: val.uuid,
          };
        return true;
      });
    }
    localMainCartId = mainCartId;
  }

  function filterDiscountOffer() {
    findDiscountStep = [];
    discountsByProduct !== null &&
      discountsByProduct !== undefined &&
      discountsByProduct.discounts_by_product !== undefined &&
      discountsByProduct.discounts_by_product.length > 0 &&
      discountsByProduct.discounts_by_product[0].promotion_type ===
        'FROM_THE_QUANTITY_OF_GOODS' &&
      discountsByProduct.discounts_by_product[0].discounts.map(
        (item, index) => {
          findDiscountStep.push({
            quantity: item.quantity,
            price_with_discount: item.price_with_discount,
          });
          return findDiscountStep;
        },
      );

    // debugger;
    findQuantity = [];
    uuidArr.map((item, index) => {
      if (offers[item] !== undefined && item === offers[item].uuid) {
        findQuantity.push(offers[item].quantity);
      }
      return findQuantity;
    });
    maxQuantityInWarehouse = Math.max.apply(null, findQuantity);

    let test = [];
    lastDiscountsByProductElem = [];
    findDiscountStep.map((item, index) => {
      if (item.quantity > maxQuantityInWarehouse) {
        test.push({
          quantity: item.quantity,
          price_with_discount: item.price_with_discount,
        });
      } else {
        lastDiscountsByProductElem.push(findDiscountStep[index]);
      }
      return test;
    });

    // discountsByProduct.discounts.map((item, index) => {
    // });

    // return Math.min.apply(null, test);
    // setSortPriceDiscount()
    return [test, lastDiscountsByProductElem.pop()];
  }

  const handleChangeCart = event => {
    localMainCartId = event.target.value;
    setFlag(flag + 1);
    getCart();
    dialogData.row.balance.map((item, index) => {
      return getCountGoodsForUuid(item.uuid, localMainCartId);
    });

    //setSelectedCart(localMainCartId);
  };
  
  const addToCard = event => {
    const items = [];
    let totalValue = 0;

    Object.values(offers).forEach(offer => {
      if (offer.quantity > 0) {
        items.push({
          item_id: row.product_id?.toString(),
          item_name: row.name,
          item_brand: row.brand,
          item_category: lastItemBreadcrumb,
          price: offer.priceCurrent,
          quantity: offer.quantity,
          google_business_vertical: 'retail',
        });
        totalValue += offer.priceCurrent * offer.quantity;
      }
    });

    if (items.length > 0) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'add_to_cart',
        currency: 'UAH',
        value: totalValue,
        items,
      });
    }

    handleClose({offers, localMainCartId});
    idDetail = '';
    addPriorityParams();
  };
  
  const onChangeQuantity = (
    quantity,
    uuid,
    price,
    priceCurrent,
    maxQuantity,
    min,
    priority,
  ) => {
    if (quantity === null || quantity === '') {
      quantity = min;
      setFlag(flag + 1);
    } else {
      setFlag(flag + 1);
    }
    quantity = parseInt(quantity);
    price = parseFloat(price);
    maxQuantity = parseInt(maxQuantity);

    if (quantity > maxQuantity) quantity = maxQuantity;
    offers[uuid] = {quantity, price, priceCurrent, uuid};
    blockUnblockWarehouse(priority, quantity);

    // let ggg = offers[uuid].quantity;

    discountQuantityArr.push(offers.filter(offers => offers.quantity));
    filterDiscountOffer();
    setAddedQuantity(quantity);
  };

  /*обработка приоритетов по складам*/
  function addPriorityParams() {
    let test = [];
    let filterPriority = [];

    let warehouseArr = [];

    if (customerGroupId && row) {
      row.balance.map((item, index) => {
        // debugger;
        let other_discount_groups = [];

        warehouseArr.push(item);

        // debugger
        item.discount_groups &&
          item.discount_groups.map((el, i) => {
            if (el.id === customerGroupId) {
              item['discount_groups_id'] = el.id;
              item['priorityWarehouse'] = el.priority;
            } else {
              other_discount_groups.push(el);
            }
            item['other_discount_groups'] = other_discount_groups;

            return item;
          });

        if (
          item.quantity_int > 0 &&
          item.discount_groups_id === customerGroupId
        ) {
          test.push(item.priorityWarehouse);
        }

        return item;
      });

      let h = test.reduce((x, y) => (x.includes(y) ? x : [...x, y]), []);
      h.map((el, index) => {
        filterPriority.push(
          warehouseArr.filter(
            warehouseArr => warehouseArr.priorityWarehouse === el,
          ),
        );
        return true;
      });

      let numbers = h;
      numbers.sort(function(a, b) {
        return a - b;
      });

      setSortArrPriority(numbers);
      setQuantityPriorityWarehouse(filterPriority);

      let g = numbers;

      if (g[0] === 0) {
        g.splice(0, 1);
      }
      setWarehouse(g);

      row.balance.map((item, index) => {
        if (item.countInCart > 0) {
          blockUnblockWarehouse(
            item.priorityWarehouse,
            item.countInCart,
            filterPriority,
            numbers,
          );
        }
        return true;
      });
    }
  }

  function blockUnblockWarehouse(priority, quantity, filterPriority, numbers) {
    let maxQuantitysum = 0;

    if (filterPriority !== undefined) {
      filterPriority.map((el, i) => {
        el.map((item, i) => {
          if (item.priorityWarehouse === priority) {
            maxQuantitysum += parseInt(item.quantity_int);
          }
          return maxQuantitysum;
        });
        return maxQuantitysum;
      });
    } else {
      quantityPriorityWarehouse.map((el, i) => {
        el.map((item, i) => {
          if (item.priorityWarehouse === priority) {
            maxQuantitysum += parseInt(item.quantity_int);
          }
          return maxQuantitysum;
        });
        return maxQuantitysum;
      });
    }

    if (priority !== 0) {
      let r = 1;

      if (quantity === maxQuantitysum && priority === 1) {
        if (numbers !== undefined) {
          numbers.map((n, i) => {
            if (n === priority) {
              if (i === 0) {
                r = i + 2;
              } else {
                r = i + 1;
              }
            }

            return r;
          });
        } else {
          sortArrPriority.map((n, i) => {
            if (n === priority) {
              if (i === 0) {
                r = i + 2;
              } else {
                r = i + 1;
              }
            }

            return r;
          });
        }
        // debugger
        setUnBlockWarehouse(r);
      } else {
        if (priority !== 0) {
          r = priority;
          setUnBlockWarehouse(r);
        }
        setUnBlockWarehouse(priority);
      }

      if (
        (priority === 2 && quantity > 0) ||
        (priority === 3 && quantity > 0)
      ) {
        setBlockButton(1);
      } else {
        setBlockButton(0);
      }
    }
  }

  const promoClose = () => {
    setPromoModal(false);
    window.scrollTo({
      top: 10,
      behavior: 'smooth',
    });
  };

  // console.log(quantity, '--------------quantity');

  const openPromoModal = (url, id) => {
    setPromoModal(true);
    // searchPromotionList(url, id);
    // setPromoModalData(dataPromotionList);
  };

  const renderProductInfo = () => (
    <>
      <div className="modal-product__info-header">
        <div>
          <h3 className="modal-product__info-title">{row?.brand}</h3>
          <div className="modal-product__info-code">{row?.article}</div>
        </div>

        <div className="modal-product__info-price">
          {row?.price} {sell_currency_id === 2 ? 'грн' : '$'}
        </div>
      </div>
      <div className="modal-product__info-desc">{row?.name}</div>
    </>
  );
  return (
    <>
      <Dialog open={dialogData.row ? true : false} onClose={handleClose}>
        <div className="modal modal-product" style={{display: 'block'}}>
          <div className="modal-content">
            <span onClick={handleClose} className="button-close">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15 1L1.15497 15M1 1L14.845 15L1 1Z"
                  stroke="#414D5F"
                  strokeOpacity="0.5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <div className="modal-body">
              <h2 className="modal-product__title">
                {t('common:add_to_basket')}
              </h2>
              <div className="modal-product__inner justify-content-between">
                <div className="modal-product__img">
                  <img src={row?.images?.thumb ?? productImage} />
                </div>
                <div className="modal-product__info" style={{width: '100%'}}>
                  {renderProductInfo()}
                  <div className="modal-product__info-block">
                    <div className="product__count-list">
                      {row.balance &&
                        row.balance.map(
                          (value, index) =>
                            (indexRows === '' || indexRows === value.uuid) &&
                            value.quantity !== '0' && (
                              <>
                                {warehouse && warehouse.length <= 1 ? (
                                  <div
                                    key={index}
                                    className={
                                      // customerGroupId && value.discount_groups_id !== customerGroupId ? clsx(classes.stBalance) :
                                      clsx(classes.stBalance)
                                    }>
                                    <div
                                      className={classes.warehouseNameHolder}>
                                      <ListItemText
                                        classes={{
                                          primary: classes.primaryPrice,
                                          secondary: classes.secondaryPrice,
                                        }}
                                        style={{
                                          opacity:
                                            customerGroupId &&
                                            value.discount_groups_id !==
                                              customerGroupId
                                              ? 0.5
                                              : 1,
                                        }}
                                        primary={value.name}
                                        /*secondary={`${'до '}${moment().add('days', 1).format('HH:mm DD.MM.YY')}`}*/
                                      />

                                      {customerGroupId &&
                                      value.discount_groups_id !==
                                        customerGroupId ? (
                                        <div>
                                          <span
                                            className={classes.warehouseHelpBtn}
                                            onClick={() =>
                                              warehouseHelp(value.uuid)
                                            }>
                                            ?
                                          </span>
                                          <div
                                            className={classes.warehouseHelp}
                                            onClick={() =>
                                              warehouseHelp(value.uuid)
                                            }></div>
                                          <Dialog
                                            onClose={handleClose}
                                            maxWidth="xs"
                                            fullWidth={true}
                                            open={
                                              warehouseHelpText === value.uuid
                                                ? true
                                                : false
                                            }>
                                            <DialogTitle
                                              id="customized-dialog-title"
                                              onClick={() =>
                                                warehouseHelp(false)
                                              }>
                                              <Grid
                                                container
                                                item
                                                xs={12}
                                                className={clsx(
                                                  classes.gridContainerHr,
                                                  classes.stMainHeaderHelp,
                                                )}>
                                                <span
                                                  className={
                                                    classes.stMainHeader
                                                  }>
                                                  {t(
                                                    'common:warehouses_available',
                                                  )}
                                                </span>
                                                <IconButton
                                                  onClick={() =>
                                                    warehouseHelp(value.uuid)
                                                  }
                                                  className="close"
                                                  size={'small'}>
                                                  <Cansel />
                                                </IconButton>
                                              </Grid>
                                              <Divider
                                                className={classes.divider}
                                              />
                                            </DialogTitle>
                                            <DialogContent
                                              className={
                                                classes.warehouseHelpContent
                                              }>
                                              <div>
                                                <Link
                                                  to="/sign-in"
                                                  className={
                                                    classes.stMainHeaderLink
                                                  }>
                                                  {t('common:should_authorize')}
                                                </Link>
                                              </div>
                                              {authentication.loggedIn ? (
                                                <>
                                                  {warehouseHelpText &&
                                                  value.other_discount_groups &&
                                                  value.other_discount_groups
                                                    .length > 0
                                                    ? t('common:shipment_text')
                                                    : ''}
                                                  {/*{console.log(value)}*/}
                                                </>
                                              ) : (
                                                <strong>
                                                  {t('common:ship_warehouse')}
                                                </strong>
                                              )}
                                            </DialogContent>
                                          </Dialog>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>

                                    <div>
                                      <div
                                        className={
                                          customerGroupId &&
                                          value.discount_groups_id !==
                                            customerGroupId
                                            ? classes.disabledWarehouse
                                            : ''
                                        }>
                                        <MyNumericInput
                                          isAllDiscountsExsist={
                                            isAllDiscountsExsist
                                          }
                                          stDisplay={'flex'}
                                          onChange={onChangeQuantity}
                                          uuid={value.uuid}
                                          price={row.price}
                                          priceCurrent={
                                            row.priceCurrent !== undefined
                                              ? row.priceCurrent
                                              : row.price_all_curr[abbr]
                                                  .price_discount
                                          }
                                          min={value.countInCart ? 1 : 0}
                                          getCount={1} //Запросить кол-во на сервере
                                          //max={value.quantity ? 1 : 0}
                                          //value={offers[value.uuid] ? offers[value.uuid].quantity : 0}

                                          max={
                                            value.quantity
                                              ? value.countInCart
                                                ? value.countInCart
                                                : 0
                                              : 0
                                          }
                                          value={
                                            offers[value.uuid]
                                              ? offers[value.uuid].quantity
                                              : value.countInCart
                                              ? value.countInCart
                                              : 0
                                          }
                                          defaultValue={''}
                                          step={1}
                                          priority={value.priorityWarehouse}
                                          // disabledWarehouse = {value.warehouse_id === priority.warehouseId}
                                          unBlockWarehouse={
                                            value.priorityWarehouse
                                          }
                                          warehouse_id={value.warehouse_id}
                                          mainCartId={localMainCartId}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    key={index}
                                    className={
                                      customerGroupId &&
                                      value.discount_groups_id !==
                                        customerGroupId
                                        ? clsx(
                                            classes.stBalance,
                                            classes.disabledWarehouse,
                                          )
                                        : clsx(
                                            classes.stBalance,
                                            'product__count-item',
                                          )
                                    }>
                                    <div
                                      className={classes.warehouseNameHolder}>
                                      <ListItemText
                                        classes={{
                                          primary: classes.primaryPrice,
                                          secondary: classes.secondaryPrice,
                                        }}
                                        primary={value.name}
                                        /*secondary={`${'до '}${moment().add('days', 1).format('HH:mm DD.MM.YY')}`}*/
                                      />

                                      {value.priorityWarehouse &&
                                      unBlockWarehouse === 1 &&
                                      (value.priorityWarehouse === 2 ||
                                        value.priorityWarehouse === 3) ? (
                                        <div>
                                          <span
                                            className={classes.warehouseHelpBtn}
                                            onClick={() =>
                                              warehouseHelp(value.uuid)
                                            }>
                                            ?
                                          </span>
                                          <div
                                            className={classes.warehouseHelp}
                                            onClick={() =>
                                              warehouseHelp(value.uuid)
                                            }></div>
                                          <Dialog
                                            onClose={handleClose}
                                            maxWidth="xs"
                                            fullWidth={true}
                                            open={
                                              warehouseHelpText === value.uuid
                                                ? true
                                                : false
                                            }>
                                            <DialogTitle
                                              id="customized-dialog-title"
                                              onClick={() =>
                                                warehouseHelp(false)
                                              }>
                                              <Grid
                                                container
                                                item
                                                xs={12}
                                                className={clsx(
                                                  classes.gridContainerHr,
                                                  classes.stMainHeaderHelp,
                                                )}>
                                                <span
                                                  className={
                                                    classes.stMainHeader
                                                  }>
                                                  {t(
                                                    'common:warehouses_available',
                                                  )}
                                                </span>
                                                <IconButton
                                                  onClick={() =>
                                                    warehouseHelp(value.uuid)
                                                  }
                                                  className="close"
                                                  size={'small'}>
                                                  <Cansel />
                                                </IconButton>
                                              </Grid>
                                              <Divider
                                                className={classes.divider}
                                              />
                                            </DialogTitle>
                                            <DialogContent
                                              className={
                                                classes.warehouseHelpContent
                                              }>
                                              <strong>
                                                {t(
                                                  'common:warehouses_priority',
                                                )}
                                              </strong>
                                              {value.priorityWarehouse === 2
                                                ? row.balance.map((w, index) =>
                                                    w.priorityWarehouse ===
                                                    1 ? (
                                                      <p key={index}>
                                                        {' '}
                                                        {w.name}
                                                      </p>
                                                    ) : (
                                                      ''
                                                    ),
                                                  )
                                                : value.priorityWarehouse === 3
                                                ? row.balance.map((w, index) =>
                                                    w.priorityWarehouse !==
                                                    3 ? (
                                                      <p key={index}>
                                                        {' '}
                                                        {w.name}
                                                      </p>
                                                    ) : (
                                                      ''
                                                    ),
                                                  )
                                                : ''}
                                            </DialogContent>
                                          </Dialog>
                                        </div>
                                      ) : customerGroupId &&
                                        value.discount_groups_id !==
                                          customerGroupId ? (
                                        <div>
                                          <span
                                            className={classes.warehouseHelpBtn}
                                            onClick={() =>
                                              warehouseHelp(value.uuid)
                                            }>
                                            ?
                                          </span>
                                          <div
                                            className={classes.warehouseHelp}
                                            onClick={() =>
                                              warehouseHelp(value.uuid)
                                            }></div>
                                          <Dialog
                                            onClose={handleClose}
                                            maxWidth="xs"
                                            fullWidth={true}
                                            open={
                                              warehouseHelpText === value.uuid
                                                ? true
                                                : false
                                            }>
                                            <DialogTitle
                                              id="customized-dialog-title"
                                              onClick={() =>
                                                warehouseHelp(false)
                                              }>
                                              <Grid
                                                container
                                                item
                                                xs={12}
                                                className={clsx(
                                                  classes.gridContainerHr,
                                                  classes.stMainHeaderHelp,
                                                )}>
                                                <span
                                                  className={
                                                    classes.stMainHeader
                                                  }>
                                                  {t(
                                                    'common:warehouses_available',
                                                  )}
                                                </span>
                                                {/*<Close onClick={handleClose} style={{cursor: "pointer"}}/>*/}
                                                <IconButton
                                                  onClick={() =>
                                                    warehouseHelp(value.uuid)
                                                  }
                                                  className="close"
                                                  size={'small'}>
                                                  <Cansel />
                                                </IconButton>
                                              </Grid>
                                              <Divider
                                                className={classes.divider}
                                              />
                                            </DialogTitle>
                                            <DialogContent
                                              className={
                                                classes.warehouseHelpContent
                                              }>
                                              {authentication.loggedIn ===
                                              true ? (
                                                <>
                                                  {warehouseHelpText &&
                                                  value.other_discount_groups &&
                                                  value.other_discount_groups
                                                    .length > 0 ? (
                                                    <strong>
                                                      {t(
                                                        'common:available_warehouse',
                                                      )}
                                                    </strong>
                                                  ) : (
                                                    <strong>
                                                      {t(
                                                        'common:available_warehouse2',
                                                      )}{' '}
                                                      {
                                                        row.balance[0]
                                                          .discount_groups[0]
                                                          .name
                                                      }
                                                    </strong>
                                                  )}

                                                  {warehouseHelpText &&
                                                  value.other_discount_groups &&
                                                  value.other_discount_groups
                                                    .length > 0
                                                    ? value.other_discount_groups.map(
                                                        (groups, index) => (
                                                          <div key={index}>
                                                            {' '}
                                                            {groups.name}
                                                          </div>
                                                        ),
                                                      )
                                                    : ''}
                                                </>
                                              ) : (
                                                <strong>
                                                  {t('common:ship_warehouse')}
                                                </strong>
                                              )}
                                            </DialogContent>
                                          </Dialog>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>

                                    <ListItemText
                                      primary={
                                        value.quantity +
                                        ' ' +
                                        t('common:pieces')
                                      }
                                      style={{
                                        textAlign: 'right',
                                        marginRight: 10,
                                      }}
                                    />

                                    <div
                                      className={
                                        value.priorityWarehouse === 0 ||
                                        (value.priorityWarehouse &&
                                          value.priorityWarehouse ===
                                            unBlockWarehouse)
                                          ? ''
                                          : classes.disabledRow
                                      }>
                                      <div
                                        className={
                                          customerGroupId &&
                                          value.discount_groups_id !==
                                            customerGroupId
                                            ? classes.disabledWarehouse
                                            : ''
                                        }>
                                        <MyNumericInput
                                          stDisplay={'flex'}
                                          onChange={onChangeQuantity}
                                          uuid={value.uuid}
                                          price={row.price}
                                          priceCurrent={
                                            row.priceCurrent !== undefined
                                              ? row.priceCurrent
                                              : row.price_all_curr[abbr]
                                                  .price_discount
                                          }
                                          min={value.countInCart ? 1 : 0}
                                          getCount={1} //Запросить кол-во на сервере
                                          //max={value.quantity ? 1 : 0}
                                          //value={offers[value.uuid] ? offers[value.uuid].quantity : 0}

                                          max={
                                            value.quantity
                                              ? value.countInCart
                                                ? value.countInCart
                                                : 0
                                              : 0
                                          }
                                          value={
                                            offers[value.uuid]
                                              ? offers[value.uuid].quantity
                                              : value.countInCart
                                              ? value.countInCart
                                              : 0
                                          }
                                          defaultValue={''}
                                          step={1}
                                          priority={value.priorityWarehouse}
                                          // disabledWarehouse = {value.warehouse_id === priority.warehouseId}
                                          unBlockWarehouse={unBlockWarehouse}
                                          blockButton={blockButton}
                                          warehouse_id={value.warehouse_id}
                                          mainCartId={localMainCartId}
                                          // highestPriorityWithZeroValue = {highestPriorityWithZeroValue}
                                          // p1 = {p1}
                                          // p2 = {p2}
                                          // p3 = {p3}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ),
                        )}
                    </div>
                    <div className="аdvertising-banner">
                      {row.promoLabel && row.promoLabel[0] !== undefined ? (
                        <>
                          {row.promoLabel.map(it => (
                            <React.Fragment key={it.promotion_id}>
                              <div
                                className="addtocart__promo"
                                onClick={() =>
                                  openPromoModal(
                                    it.promotion_url,
                                    it.promotion_id,
                                  )
                                }>
                                {/* <PromoLabel promoLabel={[it]} height={'32px'} /> */}
                                <img
                                  className="promo-iamge"
                                  src={addToCartPromo}
                                />
                                <div
                                  className="promo-title"
                                  style={{color: 'white'}}
                                  dangerouslySetInnerHTML={createMarkup(
                                    it.promotion_description,
                                  )}
                                />
                              </div>
                              <Dialog
                                onClose={promoClose}
                                maxWidth="sm"
                                fullWidth={true}
                                open={promoModal}>
                                <DialogTitle
                                  id="customized-dialog-title"
                                  onClick={() => setPromoModal(false)}>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    className={clsx(
                                      classes.gridContainerHr,
                                      classes.stMainHeaderHelp,
                                    )}>
                                    <span className={classes.stMainHeader}>
                                      {t('common:DearCustomer')}!
                                    </span>
                                    {/*<Close onClick={handleClose} style={{cursor: "pointer"}}/>*/}
                                    <IconButton
                                      onClick={() => setPromoModal(false)}
                                      className="close"
                                      size={'small'}>
                                      <Cansel />
                                    </IconButton>
                                  </Grid>
                                  <Divider className={classes.divider} />
                                </DialogTitle>
                                <DialogContent
                                  className={classes.warehouseHelpContent}>
                                  <div>
                                    <ImageWithFallback
                                      images={
                                        window.innerWidth > 900
                                          ? it.promotion_banner_tablet
                                            ? it.promotion_banner_tablet
                                            : row.banner_tablet
                                          : it.promotion_banner_handset
                                          ? it.promotion_banner_handset
                                          : row.banner_handset
                                      }
                                      imgAlt={it.promotion_name}
                                      style={{
                                        width: '100%',
                                        objectFit: 'cover',
                                      }}
                                    />
                                    {/*<img src={row.banner_tablet || it.promotion_banner_tablet}*/}
                                    {/*     alt="img"*/}
                                    {/*     style={{*/}
                                    {/*         width: '100%',*/}
                                    {/*         objectFit: "cover"*/}
                                    {/*     }}/>*/}
                                  </div>
                                  <div className="formated-text-wrapper">
                                    <h4>{it.promotion_name}</h4>
                                    <div
                                      dangerouslySetInnerHTML={createMarkup(
                                        it.promotion_description,
                                      )}
                                    />
                                  </div>
                                </DialogContent>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    padding: '10px',
                                  }}>
                                  <Link
                                    to={`/promotion/${it.promotion_url}/${it.promotion_id}`}
                                    onClick={e => {
                                      promoClose();
                                      handleClose('', '');
                                    }}>
                                    <button
                                      className="btn"
                                      style={{width: '150px'}}>
                                      {t('common:TermsOfAction')}
                                    </button>
                                  </Link>
                                </div>
                              </Dialog>
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        ''
                      )}
                      {discountsByProduct !== null &&
                      discountsByProduct !== undefined &&
                      filterDiscountOffer()[0].length > 0 &&
                      quantityInWarehouse >=
                        filterDiscountOffer()[0][0].quantity &&
                      maxQuantityInWarehouse <
                        filterDiscountOffer()[0][0].quantity ? (
                        <div className={classes.discounts_prev}>
                          {/* <div style={{width: 40, height: 40}}>
                            <img src={IconDiscount} alt="IconDiscount" />
                          </div> */}
                          <div>
                            {t('common:add_n_more') +
                              ' ' +
                              countBeforeDiscount +
                              ' ' +
                              t('common:pieces') +
                              ', ' +
                              t('common:and_get_a_best_price') +
                              ' '}
                            <PriceToStr
                              stSumma={{
                                fontStyle: 'normal',
                                fontSize: '16px',
                                fontWeight: 600,
                              }}
                              data={
                                filterDiscountOffer()[0][0].price_with_discount
                              }
                              beforeText={''}
                              text={currencyMain.simbol}
                              grn={t('common:uah')}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {authentication.loggedIn && (
                      <div className="form__field mt-3">
                        <AddToCartSelector
                          // data={cart.list_customer_cart}
                          handleChangeCart={handleChangeCart}
                          localMainCartId={localMainCartId}
                          cart={cart}
                        />
                      </div>
                    )}
                  </div>

                  <div className="modal-product__info-footer">
                    {filterDiscountOffer()[1] !== undefined ? (
                      <div style={{displey: 'flex', flexDirection: 'column'}}>
                        <PriceToStr
                          stSumma={{
                            fontStyle: 'normal',
                            fontSize: 14,
                            fontWeight: 'normal',
                            color: '#546e7a',
                            textDecoration: 'line-through',
                          }}
                          // data={getTotal(offers)[0] * currencyMainContr.koef}
                          data={getTotal(offers)[0]}
                          beforeText={''}
                          text={currencyMain.simbol}
                          grn={t('common:uah')}
                        />
                        <ListItemText
                          style={{margin: 0, padding: 0}}
                          classes={{
                            primary: classes.primaryPriceBig,
                            secondary: classes.secondaryPriceBig,
                          }}
                          primary={
                            currencyMain && currencyMain.simbol ? (
                              <PriceToStr
                                stSumma={{
                                  fontStyle: 'normal',
                                  color: 'red',
                                  fontSize: '18px',
                                }}
                                // data={getTotal(offers)[0] * currencyMainContr.koef}
                                data={
                                  filterDiscountOffer()[1].price_with_discount *
                                  maxQuantityInWarehouse
                                }
                                beforeText={''}
                                text={currencyMain.simbol}
                                grn={t('common:uah')}
                              />
                            ) : (
                              '...'
                            )
                          }
                          secondary={
                            t('common:for') +
                            ' ' +
                            maxQuantityInWarehouse +
                            ' ' +
                            t('common:pieces')
                          }
                        />
                      </div>
                    ) : null}
                    {filterDiscountOffer()[1] === undefined ? (
                      <ListItemText
                        style={{margin: 0, padding: 0}}
                        classes={{
                          primary: classes.primaryPriceBig,
                          secondary: classes.secondaryPriceBig,
                        }}
                        primary={
                          currencyMain && currencyMain.simbol ? (
                            <PriceToStr
                              // data={getTotal(offers)[0] * currencyMainContr.koef}
                              data={getTotal(offers)[0]}
                              beforeText={''}
                              text={currencyMain.simbol}
                              grn={t('common:uah')}
                            />
                          ) : (
                            '...'
                          )
                        }
                        secondary={myFormat(getTotal(offers)[1])}
                      />
                    ) : null}
                    <button
                      onClick={addToCard}
                      className={clsx(
                        'btn btn-big btn-icon btn-icon-left',
                        getTotal(offers)[1] === 0 ? classes.disabled : '',
                      )}>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.304453 0.654591C0.49957 0.454903 0.778008 0.344864 1.05645 0.348731C1.87699 0.348731 2.7225 0.519591 3.41332 0.980841C3.95227 1.33697 4.36184 1.871 4.59633 2.47076C4.72289 2.77803 4.79637 3.10322 4.86 3.42877C5.30719 5.68791 5.75227 7.94776 6.21633 10.2037C6.29121 10.5493 6.47402 10.8844 6.77109 11.0865C7.11879 11.3375 7.55789 11.4237 7.98012 11.4237C9.72738 11.4258 11.475 11.4212 13.2223 11.4258C13.5197 11.0809 13.7602 10.6928 13.9971 10.3053C14.3768 9.68939 14.7104 9.04709 15.0525 8.41006C15.4093 7.75932 15.7127 7.08115 16.0555 6.42303C16.2605 6.03631 16.7295 5.81904 17.1577 5.90729C17.6295 5.9899 17.9975 6.43252 18 6.911V6.97252C17.9845 7.13108 17.9395 7.28576 17.8703 7.42885C17.216 8.79854 16.5414 10.1619 15.7419 11.4535C15.4009 11.99 15.0441 12.5237 14.5948 12.9772C14.3205 13.2497 13.9771 13.5067 13.5714 13.4975C11.707 13.5035 9.84234 13.4993 7.97801 13.4996C7.14656 13.5014 6.30141 13.2873 5.6148 12.8074C4.88426 12.3092 4.37379 11.5161 4.18992 10.6537C3.70934 8.33693 3.25512 6.01486 2.79422 3.69385C2.73727 3.39854 2.64937 3.09338 2.43914 2.86803C2.22469 2.63072 1.90898 2.51787 1.60031 2.46865C1.26105 2.4008 0.891211 2.47674 0.574453 2.31045C0.237656 2.14697 0.0158203 1.79049 0 1.41854V1.34049C0.0189844 1.08561 0.120234 0.834591 0.304453 0.654591Z"
                          fill="#1F1F1F"
                        />
                        <path
                          d="M6.94247 14.2255C7.36645 14.1379 7.81575 14.2339 8.18278 14.4582C8.54629 14.6867 8.82438 15.0527 8.93336 15.4696C9.15661 16.1991 8.79626 17.0464 8.13461 17.4159C7.74122 17.6448 7.25149 17.7228 6.81309 17.5868C6.39016 17.4753 6.01821 17.1905 5.79286 16.8168C5.54465 16.4059 5.46239 15.8852 5.61848 15.4264C5.79004 14.8193 6.32688 14.3464 6.94247 14.2255Z"
                          fill="#1F1F1F"
                        />
                        <path
                          d="M13.1657 14.227C13.5732 14.1415 14.008 14.2224 14.3663 14.4312C15.0409 14.8088 15.4006 15.6807 15.1517 16.4193C14.953 17.1358 14.2411 17.6586 13.5004 17.6529C13.0163 17.6512 12.5339 17.436 12.2137 17.0721C11.8941 16.7164 11.7299 16.2238 11.7805 15.7471C11.8312 15.2996 12.0562 14.8703 12.4098 14.5884C12.6246 14.4034 12.8918 14.2913 13.1657 14.227Z"
                          fill="#1F1F1F"
                        />
                        <rect
                          x="7.875"
                          y="5.625"
                          width="5.625"
                          height="2.25"
                          rx="1.125"
                          fill="#1F1F1F"
                        />
                      </svg>
                      {t('common:add_to_basket')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

AddToCartDialog.propTypes = {
  dialogData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

function mapState(state) {
  const {userSelectedData, cart, dataPromotionList, authentication} = state;
  const {customer_cart_id: mainCartId, sell_currency_id} = userSelectedData;
  const {currencies, main_currency, user_perms} = state.frontendData;

  // const {customer_id, sell_currency_id} = state.userSelectedData
  //const {customer_data} = state.contragentData;
  return {
    cart,
    mainCartId,
    dataPromotionList,
    authentication,
    sell_currency_id,
    currencies,
  };
}

const actionCreators = {
  getCart: cartActions.getCart,
  getCountGoodsForUuid: cartActions.getCountGoodsForUuid,
  // searchPromotionList: searchActions.searchPromotionList,
  //setCurrencyId: userActions.setCurrencyId, //Будет установка КОРЗИНЫ
};

class Wrapper extends React.Component {
  componentDidMount() {
    // this.props.getCart();
  }

  componentDidUpdate(prevProps) {}

  /*shouldComponentUpdate(nextProps, nextState) {

        return true;
    }*/

  render() {
    //console.log('this.state', this.state);
    return <AddToCartDialog {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);

//export default AddToCartDialog;
